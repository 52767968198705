<template>
  <div class="join">
    <el-button type="primary" :size="size" round @click="toHerf" v-if="!type"
      >{{$t('enterInterview')}}</el-button
    >
    <span @click="toHerf" v-else><slot name="btn"></slot></span>
  </div>
</template>
<script>
import { getAilaDomainName,handleToVideoChatPage} from "~/baseUtils";

export default {
  props: {
    channel_id: {
      type: String | Number,
      default: "",
    },
    start_time: {
      type: String | Number,
      default: "",
    },
    end_time: {
      type: String | Number,
      default: "",
    },
    chat_type: {
      type: String | Number,
      default: "", //1-1v1，2-自由",
    },
    size: {
      type: String,
      default: "mini",
    },
  },
  data() {
    return {
      type: false,
    };
  },
  methods: {
    toHerf() {
      if (this.IS_LOGIN) {
        let path = getAilaDomainName("zh");
        // if(start_time)
        let now_time = moment(new Date()).unix();
        if (this.start_time - 900 > now_time) {
          this.$message.warning(this.$t('meeting_not_start'));
        } else if (
          this.start_time - 900 <= now_time &&
          now_time - 300 < this.end_time
        ) {
          // let _params = {
          //   channel_id: this.channel_id,
          //   user_id: this.USER_INFO.id,
          // };
          // if (this.chat_type == 2) _params.type = "daily";
          // let params = this._encode(_params);
          // let herfPath = `${path}/networkTalking/biunique?parameter=${params}&initialParams=${this.USER_INFO.id}`;
          // window.open(herfPath);
          handleToVideoChatPage(
            this.channel_id,
            this.USER_INFO.id,
            "d_order",
            true
          )
        } else {
          this.$message.warning(this.$t('meeting_not_start'));
        }
      }else {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
      }
    },
  },
  mounted() {
    this.type = this.$scopedSlots.$hasNormal;
  },
};
</script>
<style lang="less" scoped>
.join {
  display: inline-block;
}
</style>