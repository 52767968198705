<template>
  <div class="interviewAdmin">
    <div class="filter">
      <el-tabs v-model="status" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in statusList"
          :label="item.label"
          :name="item.value"
          :key="index"
        />
      </el-tabs>
    </div>
    <div v-loading="loading">
      <div class="list">
        <div v-for="(item, index) in dataList" :key="index" class="item">
          <div class="date">
            {{ item.start_time | secondFormat("LLL") }} -
            {{ item.end_time | secondFormat("T") }}
          </div>
          <div class="infoBox">
            <div class="info">
              <div class="avatarBox">
                <el-avatar
                  shape="circle"
                  :size="46"
                  :fit="'contain'"
                  :src="
                    item.avatar
                      ? item.avatar
                      : 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/grounp.png'
                  "
                ></el-avatar>
              </div>
              <div class="basicInfo marginL12">
                <div class="infoTop">
                  <div class="name" @click="getMyresume(item.user_id)">
                    <span
                      :title="item.name_en | priorFormat(item.name_zh, LOCALE)"
                      >{{
                        item.name_en | priorFormat(item.name_zh, LOCALE)
                      }}</span
                    >
                  </div>
                  <div class="jobName">
                    <span :title="item.job_name | textFormat"
                      >| {{ item.job_name | textFormat }}</span
                    >
                  </div>
                  <div class="jobLeader">
                    <span
                      :title="
                        item.person_in_charge_name_en
                          | priorFormat(item.person_in_charge_name_zh, LOCALE)
                      "
                    >
                      {{
                        item.person_in_charge_name_en
                          | priorFormat(item.person_in_charge_name_zh, LOCALE)
                      }}</span
                    >
                  </div>
                </div>
                <div class="infoBottom">
                  <span class="education">{{
                    item.level | levelRequireFormat(LOCALE)
                  }}</span>
                  <span class="marginLR4">|</span>
                  <div class="schoolName">
                    <span :title="item.school_name | textFormat">
                      {{ item.school_name | textFormat }}</span
                    >
                  </div>
                  <span class="marginLR4">|</span>
                  <span>{{ item.age | textFormat }}{{$t('year')}}</span>
                </div>
              </div>
              <div class="statusBox">
                <div
                  v-if="item.status === 6 && item.isEnd === 3"
                  class="status1"
                >
                  {{ $t("havemeetingEnd") }}
                </div>
                <div
                  v-if="item.status === 6 && item.isEnd !== 3"
                  class="status1"
                >
                  {{ $t("forInterview") }}
                </div>
                <div
                  v-if="item.invite_status === 1 && item.status === 3"
                  class="status2"
                >
                  {{ $t("pending") }}
                </div>
                <div
                  v-if="item.invite_status === 1 && item.status === 2"
                  class="status2"
                >
                  {{ $t("hasInvite") }}
                </div>
                <div
                  v-if="
                    item.invite_status === 2 &&
                    item.status !== 9 &&
                    item.status !== 10 &&
                    item.status !== 6
                  "
                  class="status1"
                >
                  {{ $t("confirmeds") }}
                </div>
                <div
                  v-if="item.invite_status === 4 || item.invite_status === 5"
                  class="status3"
                >
                  {{ $t("cancelled") }}
                </div>
                <div v-if="item.invite_status === 3" class="status4">
                  {{ $t("rejected") }}
                </div>
                <div v-if="item.status === 9" class="status3">
                  {{ $t("hire") }}
                </div>
                <div v-if="item.status === 10" class="status3">
                  {{ $t("inappropriate") }}
                </div>
              </div>
              <div>
                <div class="interviewSource">
                  <span>{{
                    item.apply_type === 1
                      ? $t("activelyApply")
                      : $t("InvitationInterview") || "-"
                  }}</span>
                  <!--                  <el-popover-->
                  <!--                      placement="bottom-start"-->
                  <!--                      title="备注"-->
                  <!--                      width="200"-->
                  <!--                      trigger="click"-->
                  <!--                      :content=" (item.remarks||item.send_postscript )| textFormat"-->
                  <!--                  >-->
                  <!--                    <i-->
                  <!--                        slot="reference"-->
                  <!--                        class="el-icon-caret-bottom"-->
                  <!--                        style="cursor: pointer"-->
                  <!--                    ></i>-->
                  <!--                  </el-popover>-->
                </div>
                <div class="interviewSourceDate">
                  {{
                    item.apply_type === 1
                      ? $t("applyAt")
                      : $t("intiveAt") || "-"
                  }}
                  {{ item.created_time | secondFormat }}
                </div>
              </div>
            </div>
            <div class="fr" style="display: flex; align-items: center">
              <el-button
                type="primary"
                :loading="item.acceptLoading"
                size="small"
                v-if="item.invite_status === 1 && item.status === 3"
                @click="checkAccept(item)"
                >{{ $t("acceptApplications") }}</el-button
              >
              <el-button
                size="small"
                v-if="item.invite_status === 1 && item.status === 3"
                :loading="item.refusedLoading"
                @click="handleRefused(item)"
                >{{ $t("refuse") }}</el-button
              >
              <joinInterview
                v-if="item.status === 6 && item.status && item.isEnd !== 3"
                :channel_id="item.one2one_live_id"
                :start_time="item.start_time"
                :end_time="item.end_time"
                :chat_type="item.chat_type"
                class="marginL8"
              >
                <el-button type="primary" size="small" slot="btn" round>{{
                  $t("enterInterview")
                }}</el-button>
              </joinInterview>
              <el-button
                class="marginL8"
                round
                size="small"
                v-if="item.status === 6 && item.isEnd !== 3"
                :loading="item.cancelInterviewLoading"
                @click="handleCancelInterview(item)"
                >{{ $t("cancelInterview") }}</el-button
              >
              <el-button
                type="primary"
                round
                size="small"
                :loading="item.hireLoading"
                v-if="item.status === 6 && item.isEnd === 3"
                @click="handleHire(item)"
                >{{ $t("hire") }}</el-button
              >
              <el-button
                type="primary"
                round
                size="small"
                :loading="item.noHireLoading"
                v-if="item.status === 6 && item.isEnd === 3"
                @click="handleNoHire(item)"
                >{{ $t("inappropriate") }}</el-button
              >
            </div>
          </div>
        </div>
        <noDataImg v-if="dataList.length === 0" />
      </div>
    </div>
    <el-dialog
      :title="$t('lookResume')"
      :visible.sync="previewResumeDialog"
      width="800px"
      v-if="previewResumeDialog"
    >
      <myInfomation :resumeInfo="form"></myInfomation>
    </el-dialog>
    <idCode
      ref="idCode"
      :channel_id="idCode.one2one_live_id"
      :chat_type="idCode.chat_type"
    ></idCode>
  </div>
</template>

<script>
import myInfomation from "~bac/components/talent/myInfomation";
import joinInterview from "~bac/components/button/joinInterview";
import idCode from "~bac/components/talentCenter/idCode";
import { secondFormat } from "@/basePlugins/filters";
export default {
  layout: "memberCenter",
  name: "interviewAdmin",
  components: { joinInterview, myInfomation, idCode },
  data() {
    return {
      status: "0", //                                                   $t('all')
      statusList: [
        {
          label: this.$t("all"),
          value: "0",
        },
        {
          label: this.$t("pending"),
          value: "1",
        },
        {
          label: this.$t("rejected"),
          value: "3",
        },
        {
          label: this.$t("forInterview"),
          value: "6",
        },
        {
          label: this.$t("havemeetingEnd"),
          value: "11",
        },
        {
          label: this.$t("cancelled"),
          value: "4",
        },
        {
          label: this.$t("hire"),
          value: "9",
        },
        {
          label: this.$t("inappropriate"),
          value: "10",
        },
      ],
      dataList: [],
      loading: false,
      form: {},
      previewResumeDialog: false,
      noHireLoading: false,
      idCode: {
        one2one_live_id: null,
        chat_type: null,
      },
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    async getDataList() {
      this.loading = true;
      let params = {
        company_id: this.USER_INFO.company_id,
        status: this.status,
      };
      if (this.person_in_charge) {
        params.person_in_charge = this.USER_ID;
      }
      if (params.status == 0) {
        params.status = "";
      }
      if (params.status == 11) {
        params.status = 6;
      }
      let res = await this.$store.dispatch(
        "baseConsole/saveCheckLabelList",
        params
      );
      console.log(res);
      this.loading = false;
      let timer = new Date();
      let nowTime = this.$moment(timer).unix();
      // 数据返回：   传的是6，9，10看status，否则看invite_status
      if (res.success) {
        res.data.forEach((item) => {
          item.acceptLoading = false;
          item.refusedLoading = false;
          item.cancelInterviewLoading = false;
          item.hireLoading = false;
          item.noHireLoading = false;
        });
        this.dataList = res.data;
        this.dataList.forEach((item) => {
          if (nowTime < item.start_time) {
            // 未开始
            item.isEnd = 1;
          } else if (item.start_time < nowTime && item.end_time > nowTime) {
            // 进行中
            item.isEnd = 2;
          } else if (nowTime > item.end_time) {
            // 结束了
            item.isEnd = 3;
          } else {
          }
        });
        // 判断6和11
        if (this.status == 11) {
          this.dataList = this.dataList.filter(
            (item) => nowTime > item.end_time
          );
        } else if (this.status == 6) {
          this.dataList = this.dataList.filter(
            (item) => nowTime < item.end_time
          );
        }

        if (this.dataList.length > 0) {
          this.dataList.forEach((item, index) => {
            if (item.birthday) {
              let num = this.$moment().unix() - item.birthday;
              item.age = Math.floor(num / 31536000);
            }
          });
        }
      }
    },
    handleClick() {
      this.getDataList();
    },
    // 判断之前同一时间段有没有接受邀请
    async checkAccept(item) {
      let params = {
        // meeting_id: 60,
        meeting_status: item.chat_type,
        user_type: 2,
        invite_status: 2,
        user_id: this.USER_ID,
        in_user_id: item.user_id,
        position_id: item.position_id,
        related_id: item.related_id || 0,
        daily_source: 2,
      };
      if (item.chat_type == 1) {
        params.agenda_id = item.agenda_id;
      } else {
        params.start_time = item.start_time;
        params.end_time = item.end_time;
      }
      params.source = this.PJSource;
      let res = await this.$store.dispatch(
        "baseConsole/talent_order_status",
        params
      );
      if (res.success) {
        // 说明之前有接受过
        if (res.data.status === 4) {
          // const h = this.$createElement;
          // const newDatas = []
          // newDatas.push(h('span', null, '当前时间段已有面试预约，取消后可继续'))
          // newDatas.push(h('span', { style: 'color: #1890FF' }, res.data.info.name))
          // newDatas.push(h('span', null, '预约面试，是否接受新的面试申请！'))
          // newDatas.push(h('div', { style: 'color: #1890FF; height: 10px' }, ''))
          // newDatas.push(h('div', { style: 'color: #666666; font-size: 12px' }, '备注：同一时间内只能参加一场面试，请您合理安排面试时间。'))
          // newDatas.push(h('div', { style: 'color: #1890FF; height: 20px' }, ''))
          // this.$alert('当前时间段已有面试预约，取消后可继续','', {
          //   message: h('div', null, newDatas),
          //   confirmButtonText: "确定",
          //   cancelButtonText: "取消",
          //   showClose: true,
          //   customClass: 'acceptConfirm'
          // }).then(() => {
          //   this.handleAccept(item)
          // }).catch(() => {})
          this.$alert(this.$t("inviteTips"), "", {
            confirmButtonText: this.$t("Confirm"),
            center: true,
            callback: (action) => {
              // this.$message({
              //   type: 'info',
              //   message: `action: ${ action }`
              // });
            },
          });
        } else {
          this.handleAccept(item);
        }
      }
    },

    // 接受邀请
    async handleAccept(item) {
      item.acceptLoading = true;
      let params = {
        meeting_type: item.chat_type,
        status: 2,
        position_apply_id: item.job_position_apply_id,
        // meeting_id: 60,
        one2one_chat_id: item.chat_id,
        position_apply_status: 6,
        invitation_type: 2,
      };
      params.source = this.PJSource;
      let res = await this.$store.dispatch(
        "baseConsole/talent_handleDaily_chat",
        params
      );
      item.acceptLoading = false;
      if (res.success) {
        if (typeof res.info === "string") {
          this.$message.warning(res.info);
        } else {
          this.$message.success(this.$t("Successful_operation"));
          this.getDataList();
        }
      } else {
        this.$message.error(this.$t("Successful_operation"));
      }
    },
    // 拒绝
    async handleRefused(item) {
      item.refusedLoading = true;
      let params = {
        meeting_type: item.chat_type,
        status: 3,
        position_apply_id: item.job_position_apply_id,
        meeting_id: 60,
        one2one_chat_id: item.chat_id,
        position_apply_status: 5,
        invitation_type: 2,
      };
      params.source = this.PJSource;
      let res = await this.$store.dispatch(
        "baseConsole/talent_handleDaily_chat",
        params
      );
      item.refusedLoading = false;
      if (res.success && res.info) {
        this.$message.success(this.$t("rejected"));
        this.getDataList();
      } else {
        this.$message.error(this.$t("rejectedError"));
      }
    },
    //  取消面试
    async handleCancelInterview(item) {
      item.cancelInterviewLoading = true;
      let params = {
        meeting_type: item.chat_type,
        status: 4,
        position_apply_id: item.job_position_apply_id,
        meeting_id: 60,
        one2one_chat_id: item.chat_id,
        position_apply_status: 8,
        invitation_type: 2,
      };
      params.source = this.PJSource;
      let res = await this.$store.dispatch(
        "baseConsole/talent_handleDaily_chat",
        params
      );
      item.cancelInterviewLoading = false;
      if (res.success && res.info) {
        this.$message.success(this.$t("Successful_operation"));
        this.getDataList();
      }
    },
    // 进入面试
    async handleEnterInterview(item) {},
    // 录用
    async handleHire(item) {
      item.hireLoading = true;
      let params = {
        job_position_apply_id: item.job_position_apply_id,
        status: 9,
      };
      let res = await this.$store.dispatch(
        "baseConsole/changeApplyStatus9_10",
        params
      );
      item.hireLoading = false;
      if (res.success) {
        this.$message.success(this.$t("Successful_operation"));
        this.getDataList();
      }
    },
    //  不合适
    async handleNoHire(item) {
      item.noHireLoading = true;
      let params = {
        job_position_apply_id: item.job_position_apply_id,
        status: 10,
      };
      let res = await this.$store.dispatch(
        "baseConsole/changeApplyStatus9_10",
        params
      );
      item.noHireLoading = false;
      if (res.success) {
        this.$message.success(this.$t("Successful_operation"));
        this.getDataList();
      }
    },
    //  请求接口，看会议是否结束
    async getMeetingInfo() {
      // let now_time = this.$moment(new Date()).unix();
      // let  data = await this.$store.dispatch("API_recruitment/getMeetingInfo", {meeting_id: 60})
      // if (data.success) {
      //   let isTimer = null;
      //   //  未开始
      //   if (data.data.start_time > now_time) {
      //     isTimer = 1;
      //     //    进行中
      //   } else if (data.data.start_time <= now_time && now_time < data.data.end_time) {
      //     isTimer = 2;
      //   } else {
      //     // 结束了
      //     isTimer = 3;
      //   }
      //   return isTimer
      // }
      let isTimer = 3;
      return isTimer;
    },
    // 简历详情
    async getMyresume(id) {
      if (!id) return;
      let data = await this.$store.dispatch(
        "baseConsole/talent_user_getMyresume",
        {
          user_id: id,
        }
      );

      let info = data.data;
      if (info.user_info) {
        info.user_info.birthday = this.$moment(
          info.user_info.birthday * 1000
        ).format("YYYY-MM-DD");
      }
      info.work_experience_info.forEach((ee, vv) => {
        if (ee.position_type == 0) {
          ee.position_type = "";
        }
        if (ee.start_time) {
          ee.times = [];
          ee.times[0] = new Date(
            this.$moment(ee.start_time * 1000).format("YYYY-MM-DD")
          );
          ee.times[1] = new Date(
            this.$moment(ee.end_time * 1000).format("YYYY-MM-DD")
          );
        } else {
          ee.times = "";
        }
      });
      info.educational_experience_info.forEach((ee, vv) => {
        if (ee.level == 0) {
          ee.level = "";
        }
        if (ee.class_type == 0) {
          ee.class_type = "";
        }
        if (ee.start_time) {
          ee.times = [];
          ee.times[0] = new Date(
            this.$moment(ee.start_time * 1000).format("YYYY-MM-DD")
          );
          ee.times[1] = new Date(
            this.$moment(ee.end_time * 1000).format("YYYY-MM-DD")
          );
        } else {
          ee.times = "";
        }
      });
      info.user_info.birthday = this.$moment(info.user_info.birthday).unix();
      this.form = info;
      this.previewResumeDialog = true;
    },
    // 二维码
    isIdCodeDialog(item) {
      this.idCode.one2one_live_id = item.one2one_live_id;
      this.idCode.chat_type = item.chat_type;
      this.$nextTick(() => {
        this.$refs.idCode.isIdCodeDialog();
      });
    },
  },
};
</script>

<style scoped lang="less">
.interviewAdmin {
  width: 100%;
  .filter {
    padding: 0 10px 0 10px;
  }
  .list {
    min-height: 598px;
    overflow-y: auto;
    padding: 0 10px 0 10px;
    position: relative;
    .item {
      .date {
        margin: 16px 0 10px;
        color: #000000;
        font-weight: 500;
        font-size: 14px;
      }
      .infoBox {
        display: flex;
        justify-content: space-between;
        background: #f8f8f8;
        padding: 0 20px;
        width: 100%;
        align-items: center;
      }
      .info {
        height: 86px;
        display: flex;
        align-items: center;
        .avatarBox {
          display: flex;
          align-items: center;
        }
        .basicInfo {
          width: 336px;
          .infoTop {
            display: flex;
            align-items: center;
            .name {
              font-size: 16px;
              font-weight: 800;
              color: #333333;
              margin-right: 8px;
              max-width: 80px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              cursor: pointer;
            }
            .jobName {
              color: #1890ff;
              margin-right: 15px;
              font-size: 14px;
              max-width: 150px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .jobLeader {
              padding: 2px 12px;
              background: #ffffff;
              border-radius: 10px;
              border: 1px solid #c8cdd0;
              color: #333333;
              font-size: 12px;
              max-width: 66px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .infoBottom {
            display: flex;
            margin-top: 6px;
            color: #666666;
            font-size: 14px;
            .marginLR4 {
              margin: 0 6px;
            }
            .schoolName {
              max-width: 154px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .statusBox {
          width: 155px;
          font-size: 14px;
          .status1 {
            color: #c8cdd0;
          }
          .status2 {
            color: #666666;
          }
          .status3 {
            color: #333333;
          }
          .status4 {
            color: #ff2b0d;
          }
          .statusDes {
            margin-top: 6px;
            font-size: 12px;
            color: #999999;
          }
        }
        .interviewSource {
          font-size: 14px;
          color: #666666;
        }
        .interviewSourceDate {
          margin-top: 6px;
          font-size: 12px;
          color: #999999;
        }
      }
      .marginL12 {
        margin-left: 12px;
      }
    }
  }
  .marginL8 {
    margin-left: 8px;
  }
}
</style>
