<template>
  <div class="filePreciewBox">
    <div class="notPdf" v-if="!isPDF">

    </div>
    <iframe :src="showFileSrc" width="100%" height="100%" id="box" frameborder="1">
    </iframe>
  </div>
</template>

<script>
import { getFileType } from "~/baseUtils";
export default {
  props: {
    fileSrc: String,
  },
  data() {
    return {
      isPDF: false,
      showFileSrc:''
    };
  },
  mounted() {
    let type = getFileType(this.fileSrc);
  
    if (type == "PDF") {
      this.isPDF = true;
      this.showFileSrc= this.fileSrc
    }else{
      this.showFileSrc= `https://view.officeapps.live.com/op/view.aspx?src=${this.fileSrc}`
    }
  },
};
</script>

<style lang="less" scoped>
.filePreciewBox {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  .notPdf{
    width: calc(100% + 3px);
    height: 48px;
    background: #000;
    position: absolute;
    z-index: 1;
  }
  iframe{
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>