<template>
  <div class="idCode">
    <!-- 二维码 -->
    <el-dialog
        :visible.sync="idcodeVisible"
        width="240px"
        :close-on-click-modal="true"
    >
      <div class="idCodeDialog">
        <div id="idCode" class="tc"></div>
        <div class="tc" style="padding-bottom: 10px; width: 160px">
          扫描二维码
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    isCustom: {
      //直播传为true,其他跳转到2.0页面的聊天传为false（默认为false,聊天时可以不传）
      type: Boolean,
      default: false,
    },
    customUrl: {
      //直播的地址
      type: String,
      default: "",
    },
    chat_type: {
      type: Number | String, //1.1v1 2.自由
      default: null,
    },
    channel_id: {
      type: Number | String, //聊天时传入
      default: null,
    },
  },
  data() {
    return {
      idcodeVisible: false,
    };
  },
  watch: {
    customUrl() {
      this.openIdCode(this.customUrl);
    },
    channel_id() {
      return this.channel_id;
    },
  },
  methods: {
    isIdCodeDialog() {
      this.idcodeVisible = true;
      let url = "";
      if (this.isCustom) {
        url = this.customUrl;
      } else {
        let _params = {
          channel_id: this.channel_id,
          user_id: this.USER_INFO.id,
        };
        if (this.chat_type == 2) _params.type = "daily";
        let params = this._encode(_params);
        url = `${window.location.origin}/networkTalking/biunique?parameter=${params}&initialParams=${this.USER_INFO.id}`;
      }
      console.log(url);
      this.openIdCode(url);
    },
    openIdCode(url) {
      this.$nextTick(() => {
        if (!this.qrcode) {
          this.qrcode = new QRCode("idCode", {
            text: url,
            width: 160,
            height: 160,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        } else {
          this.qrcode.clear();
          this.qrcode.makeCode(url);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style>
